<script lang="ts">
    import { getContext } from 'svelte';
    import axios from 'axios';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';
    import Label from '$lib/components/ui/label/label.svelte';
    import * as Select from '$lib/components/ui/select/index.js';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Calendar } from '$lib/components/ui/calendar';
    import { CalendarIcon } from 'lucide-svelte';
    import { cn } from '$lib/utils';
    import * as Table from '$lib/components/ui/table';
    import * as Popover from '$lib/components/ui/popover';
    import { DateFormatter, type DateValue, getLocalTimeZone, parseDate, today } from '@internationalized/date';

    interface SettingsContext {
        authUser: {
            username: string;
            referral_code: string;
        };
        data: {
            referrals_default_link_page: string;
        };
    }

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    interface Referral {
        id: string;
        used_by: {
            id: string;
            name: string;
            username: string;
        };
        created_at: string;
        earned: number;
        isListedForSale: boolean;
    }

    const df = new DateFormatter('en-US', { dateStyle: 'long' });

    const priceTypes = [
        { value: 'auction', label: 'Auction' },
        { value: 'static', label: 'Static' },
    ];

    let selectedPriceTypeObject = priceTypes[0];
    $: selectedPriceType = selectedPriceTypeObject.value;
    let selectedPrice = 0;
    let selectedValidity: DateValue | undefined = undefined;
    let selectedReferral: Referral | null = null;

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    $: referralLink = (() => {
        const baseUrl = getBaseUrl();
        switch (data.referrals_default_link_page) {
            case 'profile':
                return `${baseUrl}/${authUser.username}?ref=${authUser.referral_code}`;
            case 'home':
                return `${baseUrl}?ref=${authUser.referral_code}`;
            case 'register':
                return `${baseUrl}/register?ref=${authUser.referral_code}`;
            default:
                return '';
        }
    })();

    async function copyReferralLink(): Promise<void> {
        try {
            await navigator.clipboard.writeText(referralLink);
            launchToast('success', 'Success', 'Referral link copied to clipboard');
        } catch (error) {
            console.error('Failed to copy: ', error);
            launchToast('danger', 'Error', 'Failed to copy referral link');
        }
    }

    function openPriceModal(referral: Referral): void {
        if (!referral.isListedForSale) {
            selectedReferral = referral;
            selectedPriceType = '';
            selectedPrice = 0;
            selectedValidity = undefined;
        }
    }

    function validateForm(): string | null {
        if (!selectedPriceType) return 'Please select a price type';
        if (selectedPrice <= 0) return 'Please enter a valid price';
        if (!selectedValidity) return 'Please select a validity date';
        return null;
    }

    let isSubmitting = false;
    let successMessage = '';

    async function submitPrice(): Promise<void> {
        const validationError = validateForm();
        if (validationError) {
            launchToast('danger', 'Error', validationError);
            return;
        }

        isSubmitting = true;

        const dataToSend = {
            user_id: selectedReferral!.used_by.id,
            type: selectedPriceType,
            price: selectedPrice,
            validity: selectedValidity!.toString(),
        };

        try {
            const response = await axios.post('/store-referral-sell-price', dataToSend);
            successMessage = response.data.message;
            launchToast('success', 'Success', successMessage);
            selectedReferral = null;
        } catch (error: any) {
            const errorMessage =
                error.response?.status === 422
                    ? error.response.data.message
                    : 'An unexpected error occurred. Please try again.';
            launchToast('danger', 'Error', errorMessage);
        } finally {
            isSubmitting = false;
        }
    }

    function formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString();
    }

    function formatCurrency(amount: number | string | null | undefined): string {
        const numAmount = Number(amount);
        return isNaN(numAmount)
            ? '$0.00'
            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numAmount);
    }

    $: isFormValid = selectedPriceType !== '' && Number(selectedPrice) > 0 && selectedValidity !== undefined;
</script>

<div class="mx-auto max-w-3xl space-y-8">
    <section class="space-y-4">
        <h2 class="text-center text-2xl font-bold">Your Referral Link</h2>
        <p class="text-center text-gray-600 dark:text-gray-400">
            Copy your referral link and invite other people to get a fee from their earnings.
        </p>
        <div class="flex px-5">
            <Input type="text" class="text-center" readonly value="{referralLink}" aria-label="Your referral link" />
            <Button on:click="{copyReferralLink}" variant="default" class="ml-2 text-white">
                <span class="sr-only">Copy referral link</span>
                Copy
            </Button>
        </div>
    </section>

    <section class="space-y-4">
        <h2 class="text-center text-2xl font-bold">Your Referral List</h2>
        {#if data.referrals.data && data.referrals.data.length > 0}
            <div class="overflow-x-auto">
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head>Name</Table.Head>
                            <Table.Head>Since</Table.Head>
                            <Table.Head>Earned</Table.Head>
                            <Table.Head>Action</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {#each data.referrals.data as referral}
                            <Table.Row>
                                <Table.Cell>
                                    <a href="/{referral.used_by.username}" class="text-bmn-600 hover:underline">
                                        {referral.used_by.name}
                                    </a>
                                </Table.Cell>
                                <Table.Cell>{formatDate(referral.created_at)}</Table.Cell>
                                <Table.Cell>{formatCurrency(referral.earned)}</Table.Cell>
                                <Table.Cell>
                                    {#if referral.isListedForSale}
                                        <span class="text-gray-500">Already Listed</span>
                                    {:else}
                                        <Button
                                            on:click="{() => openPriceModal(referral)}"
                                            variant="default"
                                            size="sm"
                                            class="text-white"
                                        >
                                            List for Sale
                                        </Button>
                                    {/if}
                                </Table.Cell>
                            </Table.Row>
                        {/each}
                    </Table.Body>
                </Table.Root>
            </div>
        {:else}
            <p class="text-center text-gray-600 dark:text-gray-400">There are no referrals to show.</p>
        {/if}
    </section>
</div>

<Dialog.Root open="{selectedReferral !== null}" onOpenChange="{(open) => !open && (selectedReferral = null)}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Set price for {selectedReferral?.used_by.name}</Dialog.Title>
        </Dialog.Header>
        <form on:submit|preventDefault="{submitPrice}" class="space-y-4">
            <div class="space-y-4">
                <div class="space-y-2">
                    <Label for="price_type">Price Type</Label>
                    <Select.Root bind:portal="{selectedPriceTypeObject}">
                        <Select.Trigger class="w-full">
                            <Select.Value placeholder="Select Price Type" />
                        </Select.Trigger>
                        <Select.Content>
                            <Select.Group>
                                <Select.Label>Price Types</Select.Label>
                                {#each priceTypes as priceType}
                                    <Select.Item value="{priceType}">{priceType.label}</Select.Item>
                                {/each}
                            </Select.Group>
                        </Select.Content>
                    </Select.Root>
                </div>
                <div class="space-y-2">
                    <Label for="price">
                        {selectedPriceType === 'auction' ? 'Starting Price' : 'Price'}
                    </Label>
                    <Input id="price" type="number" bind:value="{selectedPrice}" min="0.01" step="0.01" required />
                </div>
                <div class="space-y-2">
                    <Label for="validity">Validity</Label>
                    <Popover.Root>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                variant="outline"
                                class="{cn(
                                    'w-full justify-start text-left font-normal',
                                    !selectedValidity && 'text-muted-foreground'
                                )}"
                                builders="{[builder]}"
                            >
                                <CalendarIcon class="mr-2 h-4 w-4" />
                                {selectedValidity
                                    ? df.format(selectedValidity.toDate(getLocalTimeZone()))
                                    : 'Pick a date'}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-auto p-0">
                            <Calendar
                                bind:value="{selectedValidity}"
                                initialFocus
                                minDate="{today(getLocalTimeZone())}"
                            />
                        </Popover.Content>
                    </Popover.Root>
                </div>
            </div>
            <Dialog.Footer>
                <Button
                    type="submit"
                    variant="default"
                    disabled="{!isFormValid || isSubmitting}"
                    class="w-full text-white"
                >
                    {#if isSubmitting}
                        <svg
                            class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Submitting...
                    {:else}
                        Submit
                    {/if}
                </Button>
            </Dialog.Footer>
        </form>
    </Dialog.Content>
</Dialog.Root>
